import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import BarIcon              from "Dashboard/Components/Core/BarIcon";



/**
 * The General Admin Menu
 * @returns {React.ReactElement}
 */
function GeneralAdminMenu() {
    const { isGeneralAdmin } = Store.useState("auth");
    const { closeMenu } = Store.useAction("core");


    // Do the Render
    if (!isGeneralAdmin) {
        return <React.Fragment />;
    }
    return <>
        <BarIcon
            variant="accent"
            url="ENTRIES"
            icon="entry"
            message="ENTRIES_NAME"
            tooltipDelay={0.2}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            variant="accent"
            url="ACTIVITIES"
            icon="activity"
            message="ACTIVITIES_NAME"
            onClick={closeMenu}
            tooltipDelay={0.2}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            variant="accent"
            url="RESERVE"
            icon="calendar"
            message="RESERVES_SINGULAR"
            tooltipDelay={0.2}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            variant="accent"
            url="SCHEDULE"
            icon="schedule"
            message="SCHEDULES_SINGULAR"
            tooltipDelay={0.2}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            variant="accent"
            url="ADMINISTRATION"
            icon="administration"
            message="ADMINISTRATION_NAME"
            tooltipDelay={0.2}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            variant="accent"
            url="CONTACT"
            icon="contact"
            message="CONTACTS_SINGULAR"
            tooltipDelay={0.2}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            variant="accent"
            url="TICKET"
            icon="ticket"
            message="TICKETS_SINGULAR"
            tooltipDelay={0.2}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            variant="accent"
            url="MEMBER"
            icon="member"
            message="MEMBERS_NAME"
            onClick={closeMenu}
            tooltipDelay={0.2}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            variant="accent"
            url="DOCUMENTS"
            icon="document"
            message="DOCUMENTS_NAME"
            onClick={closeMenu}
            tooltipDelay={0.2}
            withText
            withTooltip
            startsWith
        />
    </>;
}

export default GeneralAdminMenu;
