import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import BarIcon              from "Dashboard/Components/Core/BarIcon";



/**
 * The API Menu
 * @returns {React.ReactElement}
 */
function APIMenu() {
    const { isAPI } = Store.useState("auth");


    // Do the Render
    if (!isAPI) {
        return <React.Fragment />;
    }
    return <>
        <BarIcon
            variant="accent"
            url="RESERVE"
            icon="calendar"
            message="RESERVES_SINGULAR"
            withText
            withTooltip
            startsWith
        />
    </>;
}

export default APIMenu;
