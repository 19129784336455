import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Reserve Type Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReserveTypeEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { activities, courts, visitTypes, statuses } = Store.useState("reserveType");
    const { editElem } = Store.useAction("reserveType");


    // The Initial Data
    const initialData = {
        reserveTypeID     : 0,
        activityID        : 0,
        name              : "",
        courtIDs          : [],
        visitTypeID       : 0,
        periods           : "[{}]",
        allowsDoubles     : 0,
        maxSingles        : 2,
        maxDoubles        : 4,
        maxDailyReserves  : 0,
        maxActiveReserves : 0,
        daysAnticipation  : 0,
        minsBeforeEdit    : 0,
        status            : "Active",
        position          : 0,
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({
            ...elem,
            periods : elem?.periods?.length ? JSON.stringify(elem.periods) : "[{}]",
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("reserveType", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("reserveType", open, elemID, null, handleSet);



    // Do the Render
    return <EditDialog
        open={open}
        icon="reserve-type"
        title={elemID ? "RESERVE_TYPES_EDIT_TITLE" : "RESERVE_TYPES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="activityID"
            label="ACTIVITIES_SINGULAR"
            options={activities}
            value={data.activityID}
            error={errors.activityID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="chooser"
            name="courtIDs"
            label="COURTS_NAME"
            options={courts[data.activityID] || []}
            value={data.courtIDs}
            error={errors.courtIDs}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="visitTypeID"
            label="RESERVE_TYPES_VISIT_TYPE"
            options={visitTypes}
            value={data.visitTypeID}
            error={errors.visitTypeID}
            onChange={handleChange}
            isRequired
        />

        <InputField
            type="toggle"
            name="allowsDoubles"
            label="RESERVE_TYPES_ALLOWS_DOUBLES"
            value={data.allowsDoubles}
            error={errors.allowsDoubles}
            onChange={handleChange}
            withBorder
        />
        <Columns amount={data.allowsDoubles ? 2 : 1}>
            <InputField
                type="number"
                name="maxSingles"
                label="RESERVE_TYPES_MAX_SINGLES"
                value={data.maxSingles}
                error={errors.maxSingles}
                onChange={handleChange}
                minValue={0}
                step={1}
                isRequired
            />
            <InputField
                isHidden={!data.allowsDoubles}
                type="number"
                name="maxDoubles"
                label="RESERVE_TYPES_MAX_DOUBLES"
                value={data.maxDoubles}
                error={errors.maxDoubles}
                onChange={handleChange}
                minValue={0}
                step={1}
                isRequired
            />
        </Columns>

        <InputField
            type="fields"
            name="periods"
            title="GENERAL_PERIOD_TITLE"
            addButton="GENERAL_ADD_PERIOD"
            value={data.periods}
            errors={errors}
            onChange={handleChange}
            columns="2"
            isSortable
        >
            <InputItem
                type="chooser"
                name="days"
                label="GENERAL_DAYS"
                options="DATE_DAY_NAMES_MONDAY"
                columns="2"
                isRequired
            />
            <InputItem
                type="time"
                name="from"
                label="GENERAL_FROM"
                isRequired
            />
            <InputItem
                type="time"
                name="to"
                label="GENERAL_TO"
                isRequired
            />
            <InputItem
                type="number"
                name="singlesDuration"
                label="RESERVE_TYPES_SINGLES_DURATION"
                columns={data.allowsDoubles ? "1" : "2"}
                isRequired
            />
            <InputItem
                isHidden={!data.allowsDoubles}
                type="number"
                name="doublesDuration"
                label="RESERVE_TYPES_DOUBLES_DURATION"
                isRequired
            />
        </InputField>

        <Columns>
            <InputField
                type="number"
                name="maxDailyReserves"
                label="RESERVE_TYPES_MAX_DAILY_RESERVES"
                value={data.maxDailyReserves}
                error={errors.maxDailyReserves}
                onChange={handleChange}
                minValue={0}
                step={1}
            />
            <InputField
                type="number"
                name="maxActiveReserves"
                label="RESERVE_TYPES_MAX_ACTIVE_RESERVES"
                value={data.maxActiveReserves}
                error={errors.maxActiveReserves}
                onChange={handleChange}
                minValue={0}
                step={1}
            />
            <InputField
                type="number"
                name="daysAnticipation"
                label="RESERVE_TYPES_DAYS_ANTICIPATION"
                value={data.daysAnticipation}
                error={errors.daysAnticipation}
                onChange={handleChange}
                minValue={0}
                step={1}
            />
            <InputField
                type="number"
                name="minsBeforeEdit"
                label="RESERVE_TYPES_MINS_BEFORE_EDIT"
                value={data.minsBeforeEdit}
                error={errors.minsBeforeEdit}
                onChange={handleChange}
                minValue={0}
                step={1}
            />

            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
                value={data.status}
                error={errors.status}
                onChange={handleChange}
                hideClear
                isRequired
            />
            <InputField
                type="number"
                name="position"
                label="GENERAL_POSITION"
                value={data.position}
                error={errors.position}
                onChange={handleChange}
                minValue={0}
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReserveTypeEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default ReserveTypeEdit;
